<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://www.betared.sk" target="_blank">BetaRED</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} betared. v. 1.11</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://www.betared.sk">BetaRED</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
